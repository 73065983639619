import { useRef, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

import { faBars, faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CanvasCart({ children }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <FontAwesomeIcon
        icon={faCartArrowDown}
        size="1x"
        flip="horizontal"
        onClick={handleShow}
      />

      <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="cart-conva position-relative ">{children}</div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
