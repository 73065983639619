import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { logDOM } from "@testing-library/react";
import EditBrand, { getData } from "./EditBrand";
import { ContextPro } from "../../../App";
import BreadCrumb from "../../BreadCrumb";
import Loader from "../../loader";
import BtnAdmin from "../BtnAdmin";
import ChooseImg from "../ChooseImg";
import InputComponent from "../InputComponent";
import TaHeadAdmin from "../TaHeadAdmin";

import { adminPanelAPIs } from "../../../utils/config";
import { urlApiEd, urlApiGet } from "../../../utils/utilsFuc";

export default function ShowBrand() {
  const [search, setSearch] = useState([]);

  const { dataBrand, setDataBrand } = useContext(ContextPro);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(2);
  const [moreBtn, setMoreBtn] = useState(true);
  const [loader1, setloader1] = useState(true);
  const [loader2, setloader2] = useState(false);

  /*  const getMoreData = () => {
    setIndex(index + 1);
    urlApiGet(adminPanelAPIs.Brand.getBrandURL, {
      pageIndex: index,
    })
      .then((e) => {
        setloader2(false);
        setDataBrand((prev) => [...prev, ...e]);
        console.log(e);

        e.length ? setMoreBtn(true) : setMoreBtn(false);
      })
      .catch((e) => console.log(e));
  }; */

  useEffect(() => {
    if (dataBrand.length) setloader1(false);
  }, [dataBrand]);

  function SearchAdmin(value = "") {
    setSearch(dataBrand?.filter((e) => e.name.includes(value)));
  }
  return (
    <div className=" container">
      <BreadCrumb />
      <Link to={"/Admin/Add"}>
        <BtnAdmin title={"Add"} bgcolor="green" />
      </Link>

      <div className=" search-details">
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => SearchAdmin(e.currentTarget.value)}
        />
        <div>ok</div>
      </div>
      <div className=" table-admin">
        {loader1 ? (
          <Loader />
        ) : (
          <table className=" ">
            <TaHeadAdmin
              arrtitle={["Name", "photo", "action"]}
              key={"table-brand"}
            />
            <tbody>
              {(search.length ? search : dataBrand)?.map((e) => (
                <tr>
                  <td>{e.name}</td>
                  <td>
                    <img src={`${e?.photo}`} alt="" />
                  </td>
                  <td className=" d-flex justify-content-center align-items-center gap-3">
                    <Link to={"/Admin/Edit"} state={{ id: e.id, name: e.name }}>
                      <BtnAdmin title={"Edit"} bgcolor="green" />
                    </Link>

                    <BtnAdmin title={"Details"} bgcolor="#2196f3" />
                    <BtnAdmin
                      title={"Delet"}
                      onclick={() => {
                        urlApiEd(adminPanelAPIs.Brand.deleteBrandURL(e.id));
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
/* export function editData(){

}
 */
/*    {moreBtn? (
        <BtnAdmin
          title={loader2 ? <Loader /> : "More data"}
          onclick={() => {
            
         
          }}
        />
      ) : (
        ""
      )} */
