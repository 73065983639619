import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { faAngleDown, faGrip, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CartProduct from "./CartProduct";
import CollsFilter from "./filter";
import Tip from "./tip";
import BarLink from "../../footer/BarLink";
import Loader from "../../loader";

export default function Products({ data, setdata }) {
  const [open, setOpen] = useState(true);
  const refShow = useRef();
  const refgrid = useRef();
  return (
    <div className="Product-body position-relative">
      <div className="filter-moblie"></div>
      {"ontouchstart" in window ? <BarLink /> : <></>}
      <div className="info position-relative">
        <div ref={refShow} className="info-1">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab modi
          neque id. Sequi, voluptatem? Enim sint, est, minus nihil vitae nobis
          sapiente fugit blanditiis ad quidem animi delectus hic officia. Lorem
          ipsum dolor, sit amet consectetur adipisicing elit. Quidem voluptatem
          praesentium laboriosam itaque vel assumenda possimus cum libero
          dignissimos impedit. Maiores, ab rem ipsa optio pariatur iure repellat
          minus beatae! Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptates quis animi ducimus laborum inventore porro adipisci ex.
          Consequatur molestiae quaerat nemo cupiditate fuga quia eaque, saepe,
          minima iure molestias laboriosam. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Voluptates quis animi ducimus laborum
          inventore porro adipisci ex. Consequatur molestiae quaerat nemo
          cupiditate fuga quia eaque, saepe, minima iure molestias laboriosam.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          quis animi ducimus laborum inventore porro adipisci ex. Consequatur
          molestiae quaerat nemo cupiditate fuga quia eaque, saepe, minima iure
          molestias laboriosam. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quis animi ducimus laborum inventore
          porro adipisci ex. Consequatur molestiae quaerat nemo cupiditate fuga
          quia eaque, saepe, minima iure molestias laboriosam. Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Voluptates quis animi ducimus
          laborum inventore porro adipisci ex. Consequatur molestiae quaerat
          nemo cupiditate fuga quia eaque, saepe, minima iure molestias
          laboriosam.
        </div>
        <span
          className="show-more"
          onClick={() => {
            refShow.current.classList.toggle("show-div");
          }}
        >
          {" "}
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ marginRight: "5px", fontWeight: "700" }}
          />
          show more
        </span>
        <div className="shadow-div"></div>
      </div>
      <div className="main-products">
        <div className="product-filter">
          <div className="tool-filter">
            <div className="grid-list">
              <span
                onClick={() => {
                  refgrid.current.classList.add("product-grid");
                  refgrid.current.classList.remove("product-list");
                }}
              >
                <Tip text={"grid"}>
                  <FontAwesomeIcon icon={faGrip} />
                </Tip>
              </span>
              <span
                onClick={() => {
                  refgrid.current.classList.remove("product-grid");
                  refgrid.current.classList.add("product-list");
                }}
              >
                {" "}
                <Tip text={"list"}>
                  <FontAwesomeIcon icon={faList} />
                </Tip>
              </span>
            </div>
            <div className="sort-filter">
              <div className="input-filter">
                <label htmlFor="">Sort By :</label>
                <select id="">
                  <option value="">Defualt</option>
                </select>
              </div>
              <div className="input-filter">
                <label htmlFor="">show:</label>
                <select id="">
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div ref={refgrid} className="main-product-body product-grid">
          {data?.length ? (
            data.map((e) => (
              <div className=" card-product">
                <CartProduct
                  name={e.title}
                  price={e.price}
                  description={e.description}
                  outofstock={e.outOfStock}
                  photo={e.productAttchments}
                  brand={e.brandId}
                  id={e.id}
                  keyOfValue={e.productMoreInfos}
                  discount={e.discount}
                  categoryid={e.categoryId}
                  setdata={setdata}
                  data={data}
                />
              </div>
            ))
          ) : (
            <div className=" container fs-5 fw-medium  my-4">
              Now , No product in this is list
              <Link to={"/"}>
                <div
                  className=" text-white py-2 px-4 m-auto mt-3"
                  style={{ background: "#ff0055", width: "fit-content" }}
                >
                  Continue
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
