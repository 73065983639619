import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap';

 function ClientOrder({e}) {
    const [open, setOpen] = useState(false);
  return (
    <div className="mt-4 container ">
        <div
          onClick={() => setOpen(!open)}
          aria-controls="order-admin"
          aria-expanded={open}
          className=" d-flex justify-content-between align-items-center bg-success-subtle px-2 border-bottom border-1 border-black"
        >
          <span className="fs-5 fw-medium ">Client : <span className=" fw-light">{e?.user?.name}</span></span>
          <span className="fs-5 fw-medium ">Address : <span className=" fw-light">{e?.address}</span></span>
          <span className="fs-5 fw-medium ">
            Number : <span className=" fw-light">{e?.user?.phoneNumber}</span>
          </span>
          <span className="fs-5 fw-medium ">Total : <span className=" fw-light">{e?.totalAmount}</span> </span>
          <button className="fs-3 text-white my-2 px-4 border-0 bg-success">
            {open?"-":"+"}
          </button>
        </div>
        <Collapse in={open}>
          <div id="order-admin " className="">
            <div className=" px-2 d-flex justify-content-between align-items-center">
              <span style={{ width: "90px",fontWeight:"bold",fontSize:"18px",flexGrow:"1"  }}>title</span>
              <span style={{ width: "90px",fontWeight:"bold",fontSize:"18px" }}>image</span>
              <span style={{ width: "90px",fontWeight:"bold",fontSize:"18px" }}>count</span>
              <span style={{ width: "90px",fontWeight:"bold",fontSize:"18px" }}>price</span>
              <span style={{ width: "90px",fontWeight:"bold",fontSize:"18px" }}>priceTotle</span>
            </div>
            {e?.orderItems?.map((e) => (
              <div className="p-2 d-flex justify-content-between align-items-center">
                <span style={{ width: "90px" , wordBreak:"break-all",flexGrow:"1" }}>{e?.product?.title}</span>
                <img
                  src={e?.product?.productAttchments[0]?.photo}
                  style={{
                    width: "90px",
                    height: "90px",
                    objectFit: "contain",
                  }}
                />
                <span style={{ width: "90px" ,textAlign:"center", wordBreak:"break-all" }}>{e?.count}</span>
                <span style={{ width: "90px" , wordBreak:"break-all" }}>{e?.price}</span>
                <span style={{ width: "90px" , wordBreak:"break-all" }}>{e?.totalAmount}</span>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
  )
}
export default ClientOrder;