import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// import required modules
// import ReactImageZoom from "react-image-zoom";
import { Zoom } from "reactjs-image-zoom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { EffectFade, FreeMode, Navigation, Thumbs } from "swiper/modules";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Admin } from "../../utils/AdminUser";
import { adminPanelAPIs } from "../../utils/config";
import { urlApiEd, urlApiGet } from "../../utils/utilsFuc";

export default function InfoSwpier({ data }) {
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const location = useLocation();
  const [dataImg, setDataImg] = useState([]);
  const ref = useRef();

  useEffect(() => {
    setDataImg(data?.productAttchments);
  }, [data]);
  function deleteImage(id) {
    urlApiEd(adminPanelAPIs.ProductAttchment.deleteCategoriesURL(id)).then(
      (e) => {
    
        setDataImg((prev) => prev?.filter((d) => d.id !== id));
        /*  urlApiGet(adminPanelAPIs.Product.getProductURL, {
      id: photo?.productId,
    }).then((e) => {
      setData(e);
      console.log(e);
    }); */
      }
    );
  }
  return (
    <div className="swpier-product">
      <div className="swpier-one" style={{ height: `${ref.current}` }}>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={5}
          slidesPerView={5}
          freeMode={true}
          direction="vertical"
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {dataImg?.map((photo) => (
            <SwiperSlide>
              {Admin() ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    
                    deleteImage(photo?.id);
                  }}
                  style={{
                    position: "absolute",
                    fontSize: "12px",
                    right: "0",
                    top: "0",
                    border: "none",
                    background: "red",
                    color: "white",
                    zIndex: "100",
                  }}
                >
                  x
                </button>
              ) : (
                ""
              )}
              <img src={photo?.photo} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="swpier-two " ref={ref}>
        <Swiper
          style={{}}
          // grabCursor={true}
          // centeredSlides={true}
          slidesPerView={1}
          /*  effect={"fade"} */
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Thumbs, EffectFade]}
          className=""
        >
          {dataImg?.map((photo) => (
            <SwiperSlide>
              <Zoom
                // width in percent default is 100%
                // height of the box
                // width of the box
                position="center" // cover
                //  imagesrc={Image} // Image component | URL
                size={150} // it is in percent
                bgsize="cover" // background-size
                cursor="zoom-in" // pointer
                borderpixel={0} // size of border
                bordercolor="red" // color of border
                style={{
                  maxWidth: "100% ",
                  height: "100%",
                }} // add custom style
                className="img-box" // classname for box
                color="red" // color when image not loaded
                imagesrc={photo?.photo}
              ></Zoom>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
