import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";

import MobHeader from "./mobile/header/MobHeader";
import MobTopBar from "./mobile/header/MobTopBar";
import BtnAdmin from "./public_componet/Admin/BtnAdmin";
import InputComponent from "./public_componet/Admin/InputComponent";
import AddBrand from "./public_componet/Admin/PageBrand/AddBrand";
import EditBrand from "./public_componet/Admin/PageBrand/EditBrand";
import ShowBrand from "./public_componet/Admin/PageBrand/ShowBrand";
import AddCategory from "./public_componet/Admin/PageCategory/AddCategory";
import EditCategory from "./public_componet/Admin/PageCategory/EditCategory";
import ShowCategory from "./public_componet/Admin/PageCategory/ShowCategory";
import PageOrder from "./public_componet/Admin/PageOrder/PageOrder";
import AddProduct from "./public_componet/Admin/PageProduct/AddProduct";
import EditProduct from "./public_componet/Admin/PageProduct/EditProduct";
import SearchAdmin from "./public_componet/Admin/SearchAdmin";
import BarLink from "./public_componet/footer/BarLink";
import Footer from "./public_componet/footer/Footer";
import HoverCart from "./public_componet/page_cart/HoverCart";
import Page_Cart from "./public_componet/page_cart/Page_Cart";
import Home from "./public_componet/page_home/Home";
import CartProduct from "./public_componet/page_offer/Cartproduct/CartProduct";
import NotificationAdd from "./public_componet/page_offer/Cartproduct/NotificationAdd";
import PageProduct from "./public_componet/page_offer/Cartproduct/PageProduct";
import PageLogin from "./public_componet/PageLogin/PageLogin";
import Register from "./public_componet/PageLogin/Register";
import SignIn from "./public_componet/PageLogin/SignIn";
import PageSearch from "./public_componet/PageSearch/PageSearch";
import Page_Info from "./public_componet/production_info/Page_Info";
import WebHeader from "./website/header/WebHeader";
import WebTopBar from "./website/header/webTopBar";

import { Admin } from "./utils/AdminUser";
import { logoutAllTabs } from "./utils/auth";
import { adminPanelAPIs } from "./utils/config";
import ScrollToTop from "./utils/ScrollToTop";
import { useOnlineStatus } from "./utils/useOnline";
import { detectMob, urlApiEd, urlApiGet } from "./utils/utilsFuc";

// 1- external modules: react, react-router-dom, bootstrap
// 2- components
// 3- config + utils
// 4- style
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./mobile/header/mobheader.css";
import "./public_componet/page_cart/shoppingCart.css";
import "./website/header/header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";

export const ContextPro = createContext();
function App() {
  const isOnline = useOnlineStatus();
  const [itemsCart, setItemsCart] = useState([]);
  const [idUser, SetIdUser] = useState({});
  const [idcart, SetIdCart] = useState([]);
  const [showCanvas, setShowCanvas] = useState(false);
  const [dataCate, setDataCate] = useState([]);
  const [dataMontoris, setDataMontoris] = useState([]);
  const [dataBrand, setDataBrand] = useState([]);
  const [notificationAdd, setNotificationAdd] = useState();

  const [count, setCount] = useState(1);
  const adminList = {
    brand: "Brand",
    categroy: "Categroy",
    order: "Order",
  };
  const cart = JSON.parse(localStorage.getItem("cart")) || [];

  useEffect(() => {

    logoutAllTabs();
    window.history.scrollRestoration = "manual";
    isOnline &&
      urlApiGet(adminPanelAPIs.Categotry.getCategoriesURL, {
        pageSize: 100,
      })
        .then((e) => {
          setDataCate(e.list);
        })
        .catch((e) => {
          setDataCate([]);
        });

    isOnline &&
      urlApiGet(adminPanelAPIs.Brand.getBrandURL, {
        pageSize: 100,
      })
        .then((e) => {
          setDataBrand(e.list);
        })
        .catch((e) => {
          setDataBrand([]);
        });
    isOnline &&
      urlApiGet(adminPanelAPIs.Product.getProductURL, {
        pageSize: 20,
      })
        .then((e) => {
          setDataMontoris(e.list);
        })
        .catch((e) => { });

    if (localStorage.getItem("token")) {
      urlApiEd(
        adminPanelAPIs.Account.userCurrent,
        {},
        {
          "Content-Type": "application/json",
          " Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
      )
        .then((e) => {
          SetIdUser(e);

          cart?.length &&
            !e.roles.length &&
            cart?.map((e) => {
              urlApiEd(
                adminPanelAPIs.cartAdd.addCart,
                {
                  productId: parseInt(e.id),
                  count: parseInt(e.count),
                },
                {
                  "Content-Type": "application/json",
                  " Authorization": `Bearer ${localStorage.getItem("token")}`,
                }
              ).then((e) => {
              });
            });
        })
        .catch((e) => {
          SetIdUser(false);

          window.open("/", "_self");
        });

      /*    const cart = JSON.parse(localStorage.getItem('cart')) || [];
       cart?.length && 
         urlApiEd(adminPanelAPIs.cartAdd.addCart, {
           cartId: parseInt(idcart[0]?.id),
           productId: parseInt(cart[0]?.id),
           count: parseInt(cart[0]?.count)
         },{
           "Content-Type": "application/json",
           " Authorization": `Bearer ${localStorage.getItem("token")}`,
         })
  */

      setTimeout(() => {
        !idUser?.roles?.length &&
          urlApiEd(
            adminPanelAPIs.Cart.getCart,
            {},
            {
              "Content-Type": "application/json",
              " Authorization": `Bearer ${localStorage.getItem("token")}`,
            }
          )
            .then((e) => {
              SetIdCart(e.list);
            })
            .catch((e) => { });
        localStorage.removeItem("cart");
      }, 1000);
    }
  }, []);

  /*   useEffect(()=>{
      cart?.length&&cart?.map(e=>{
        urlApiEd(adminPanelAPIs.cartAdd.addCart, {
          cartId: parseInt(idcart[0]?.id),
          productId: parseInt(e.id),
          count: parseInt(e.count)
        },{
          "Content-Type": "application/json",
          " Authorization": `Bearer ${localStorage.getItem("token")}`,
        })
  
      })
    },[cart]) */

  return isOnline ? (
    <div className="App">
      {localStorage.getItem("token") && idUser?.roles?.length ? (
        <BrowserRouter>
          <ScrollToTop>
            <ContextPro.Provider
              value={{
                dataCate,
                adminList,
                setDataCate,
                dataBrand,
                setDataBrand,
                idUser,
                SetIdUser,
              }}
            >
              <div>
                {detectMob() ? "" : <WebHeader data={adminList} />}
                <MobHeader />
              </div>
              <Routes>
                <Route caseSensitive
                  path={`/Admin/${adminList.brand}`}
                  Component={ShowBrand}
                />
                <Route caseSensitive path="/Admin/Edit" Component={EditBrand} />
                <Route caseSensitive path="/Admin/Add" Component={AddBrand} />
                <Route
                  path={`/Admin/${adminList.categroy}`}
                  Component={ShowCategory}
                />
                <Route caseSensitive path="/Admin/AddCate" Component={AddCategory} />
                <Route caseSensitive path="/Admin/EditCate" Component={EditCategory} />
                <Route caseSensitive path={`/Admin/Addproduct`} Component={AddProduct} />
                <Route
                  path="/Admin/product/:type/:id"
                  element={<PageProduct />}
                />
                <Route caseSensitive path="/Admin/search/:search" element={<PageSearch />} />
                <Route caseSensitive
                  path="/Admin/product/:type/:id/:name"
                  Component={Page_Info}

                />
                <Route caseSensitive path="/Admin/product/Edit" element={<EditProduct />} />
                <Route caseSensitive path="/Admin/Order" element={<PageOrder />} />
                <Route caseSensitive path="/Admin/name/:title/:name" element={<Page_Info />} />
              </Routes>
              <Footer />
            </ContextPro.Provider>
          </ScrollToTop>
        </BrowserRouter>
      ) : ''}
      {((localStorage.getItem("token") && !idUser?.roles?.length) || !localStorage.getItem('token')) ? (
        <BrowserRouter>
          <ScrollToTop>
            <ContextPro.Provider
              value={{
                dataCate,
                adminList,
                setDataCate,
                dataBrand,
                setDataBrand,
                idUser,
                SetIdUser,
                idcart,
                SetIdCart,
                notificationAdd,
                setNotificationAdd,
                itemsCart,
                setItemsCart,
                dataMontoris,
              }}
            >
              <NotificationAdd />
              <div>
                {detectMob() ? "" : <WebHeader />}
                <MobHeader />
              </div>
              <Routes>
                <Route caseSensitive path="/" element={<Home />} />

                <Route caseSensitive path="/product/:type?/:id?" element={<PageProduct />} />
                <Route caseSensitive path="/product/:type?" element={<PageProduct />} />
                <Route caseSensitive path="/search/:search" element={<PageSearch />} />
                <Route caseSensitive path="/login" element={<PageLogin />} />
                <Route caseSensitive path="/login/signin" element={<SignIn />} />
                <Route caseSensitive path="/login/register" element={<Register />} />
                <Route
                  caseSensitive
                  path="/product/:type?/:id?/:name"
                  element={<Page_Info />}
                />
                <Route caseSensitive path="name/:title/:name" element={<Page_Info />} />
                <Route caseSensitive path="/cart" element={<Page_Cart />} />
              </Routes>
              {/* /product/:type/:id/:name */}
              <Footer />
            </ContextPro.Provider>
          </ScrollToTop>
        </BrowserRouter>
      ) : ""}
    </div>
  ) : (
    <div className=" container vh-100 bg-white  d-flex justify-content-center align-items-center  flex-column">

      <div className=" text-center px-3 py-4 border-black border  rounded  shadow-sm">
        <FontAwesomeIcon icon={faWifi}  style={{
          color:'red'
        }}/>
        <div className=" text-center">
          Make sure you are connected to the internet.
        </div>
      </div>


    </div>
  );
}

export default App;
/* import React, { useState, useEffect } from 'react';

const ItemList = ({ items }) => {
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    items.forEach((item, index) => {
      setTimeout(() => {

        setVisibleItems((prev) => [...prev, item]);
        setTimeout(() => {
          setVisibleItems((prev) => prev.filter((i) => i !== item));
        }, 2000); // Time after which the item disappears
      }, index * 500); // Time interval between items appearing
    });
  }, [items]);

  return (
    <div>
      {visibleItems.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};

const App = () => {
  const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];

  return (
    <div>
      <h1>Items</h1>
      <ItemList items={items} />
    </div>
  );
};

export default App; */

/* import React, { useState } from 'react';

const App = () => {
    const [data, setData] = useState({
        items: [{ id: 1, name: 'Item 1' }, { id: 2, name: 'Item 2' }]
    });

    const addItem = (newItem) => {
        setData(prevData => ({
            ...prevData,
            items: [...prevData.items, newItem]
        }));
    };

    return (
        <div>
            {data.items.map(item => (
                <div key={item.id}>
                    <span>{item.name}</span>
                </div>
            ))}
            <button onClick={() => addItem({ id: 3, name: 'Item 3' })}>Add Item</button>
        </div>
    );
};

export default App; */
