import { useContext, useEffect, useRef, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";

import {
  faBars,
  faCartArrowDown,
  faMinus,
  faPerson,
  faPlus,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinksCanvas from "./LinksCavnas";
import { ContextPro } from "../../App";
import CanvasCart from "../../public_componet/Canvascart";
import CanvasLink from "../../public_componet/CanvasLink";
import HoverProducts from "../../public_componet/page_cart/HoverProduct";
import HoverProduct from "../../public_componet/page_cart/HoverProduct";
import TotalPrice from "../../public_componet/page_cart/TotalPrice";
import CategoryList from "../../website/header/CategoriesList";

import { Admin } from "../../utils/AdminUser";
import { adminPanelAPIs } from "../../utils/config";
import { urlApiGet } from "../../utils/utilsFuc";

export default function MobMidBar() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const re = useRef();
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);
  const [blurResult, setBlurResult] = useState(true);
  const { dataCate, idcart, SetIdCart, idUser } = useContext(ContextPro);

  const [renderData, setRenderData] = useState([]);
  function setChildsToCat(allcategories, levelZeroCat) {
    levelZeroCat.forEach((item) => {
      let itemId = item.id;
      let childs = allcategories.filter(
        (category) => category.parentId === itemId
      );
      if (childs.length) {
        item.childs = childs;
        setChildsToCat(allcategories, childs);
      }
    });
  }
  useEffect(() => {
    if (dataCate?.length) {
      let levelZeroCat = dataCate?.filter((item) => !item.parentId);
      setChildsToCat(dataCate || [], levelZeroCat || []);
      setRenderData(levelZeroCat);
    }
  }, [dataCate]);

  const searchProduct = (e) => {
    e
      ? urlApiGet(adminPanelAPIs.Product.getProductURL, {
          title: e,
        }).then((e) => {
          setResult(e.list);
        })
      : setResult([]);
    //setSearch(e.target.value);
  };
  useEffect(() => {
    /* urlApiGet(adminPanelAPIs.Product.getProductURL, {
      title: search,
    }).then((e) => {
      console.log(e.list);
    }) */
    const timeout = setTimeout(() => {
      searchProduct(search);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);
  useEffect(() => {
    setSearch("");
  }, [window.location.pathname]);

  return (
    <div>
      <div className="container pt-4 d-flex justify-content-between align-items-center">
        <div className="py-2">
          <Link to={"/"}>
            <img src={require("./logo.jpg")} style={{ height: "40px" }} />
          </Link>
        </div>

        <div className="cart-account">
          <div className="icon-bar">
            <CanvasLink>
              <div className=" px-3">
                {renderData.map((cat, index) => {
                  return !cat?.childs?.length ? (
                    <li>
                      <Link
                        className="section-item"
                        to={`${Admin() ? "/Admin" : ""}/product/category/${
                          cat.name
                        }`}
                        state={{
                          id: cat.id,
                        }}
                      >
                        {cat.name}
                      </Link>
                    </li>
                  ) : (
                    <>
                      <LinksCanvas item={cat} />
                    </>
                  );
                })}
              </div>
            </CanvasLink>
          </div>

          <div className="icon-bar">
            <>
              <div
                onMouseEnter={() => {
                  setOpen(!open);
                }}
                onMouseLeave={() => setOpen(!open)}
                aria-controls="search-item"
                aria-expanded={open}
                className="colls-search"
              >
                <FontAwesomeIcon icon={faSearch} size="1x" />

                <Collapse in={open} timeout={100} unmountOnExit>
                  <div id="search-item">
                    <input
                      value={search}
                      ref={re}
                      type="text"
                      placeholder="search"
                      name=""
                      id=""
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <div
                      onMouseDown={() => {
                        !blurResult && setBlurResult(true);
                      }}
                      className="hover-cart"
                      style={{
                        display: `${`${result.length ? "block" : "none"}`}`,
                        transform: "translateY(40px)",
                        opacity: "1",
                        width: "100%",
                        top: "-20px",
                      }}
                    >
                      <div className=" position-relative hover-cart-1">
                        <ul
                          style={{
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          {result?.map((e) => (
                            <Link
                              to={`${Admin() ? "/Admin/" : ""}name/${
                                e.title.split(" ").join("-") + "/" + e.id
                              }`}
                              /*   onMouseDown={() => {
                                navigate(`${Admin()?"/Admin":""}name/${e.title.split(' ').join('-') + "/" + e.id}`);
                              }} */
                            >
                              <li className="mb-2 d-flex px-3">
                                <span className=" me-2">
                                  <img
                                    src={e?.productAttchments[0]?.photo}
                                    alt=""
                                    style={{ width: "50px", height: "60px" }}
                                  />
                                </span>
                                <span>
                                  <div style={{ width: "fit-content" }}>
                                    {e.title}
                                  </div>
                                  <div style={{ width: "fit-content" }}>
                                    EGP {e.price}
                                  </div>
                                </span>
                              </li>
                            </Link>
                          ))}
                          <Link
                            to={`${Admin() ? "/Admin" : ""}/search/${search}`}
                            /*  onMouseDown={() => {
                              navigate(`/search/${search}`);
                            }} */
                          >
                            <li className="mb-2 d-flex px-3">View More</li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </>
          </div>
          <div className="icon-bar ">
            <CanvasCart>
              {Admin()
                ? ""
                : localStorage.getItem("cart")
                ? JSON.parse(localStorage.getItem("cart"))?.map((e) => (
                    <HoverProduct data={e} />
                  ))
                : idcart[0]?.cartProducts?.map((e) => (
                    <HoverProduct data={e} />
                  ))}

              <TotalPrice />
            </CanvasCart>
          </div>
        </div>
      </div>
    </div>
  );
}
