import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ContextPro } from "../../../App";
import BreadCrumb from "../../BreadCrumb";
import BtnAdmin from "../BtnAdmin";
import TaHeadAdmin from "../TaHeadAdmin";

import { adminPanelAPIs } from "../../../utils/config";
import { urlApiEd, urlApiGet } from "../../../utils/utilsFuc";

export default function ShowCategory() {
  const { dataCate, setDataCate } = useContext(ContextPro);
  const [search, setSearch] = useState([]);

  //   const [data, setData] = useState(dataCate);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    SearchAdmin();
    if (dataCate.length) setLoading(false);
  }, [dataCate]);
  const handleDeleteCat = (id) => {
    // start loader
    setLoading(true);
    // delete
    urlApiEd(adminPanelAPIs.Categotry.deleteCategoriesURL(id))
      .then(() => {
        // stop loader
        setLoading(false);

        // show notification
        // update data cat
        setDataCate((prev) => prev.filter((item) => item.id !== id));
      })
      .catch((err) => {
       

        setLoading(false);
      });
  };

  function SearchAdmin(value = "") {
    setSearch(
      dataCate?.filter((e) =>
        (
          ((e.name +e.description).toLowerCase()).includes(value)
      )
    )
    );
  }



  return (
    <div className=" container">
      <BreadCrumb />
      <Link to={"/Admin/AddCate"}>
        <BtnAdmin title={"Add"} bgcolor="green" />
      </Link>

      <div className=" search-details">
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => SearchAdmin(e.currentTarget.value)}
        />
        <div>ok</div>
      </div>
      <div className=" table-admin">
        <table className=" ">
          <TaHeadAdmin
            arrtitle={["Name", "Description", "ParentId", "action"]}
            key={"table-brand"}
          />
          <tbody>
            {(search.length ? search : dataCate)?.map((e) => (
              <tr>
                <td>{e.name}</td>
                <td>{e.description}</td>
                <td>{dataCate?.map((t) => t.id == e.parentId && t.name)}</td>
                <td className=" d-flex justify-content-center align-items-center gap-3">
                  <Link
                    to={"/Admin/EditCate"}
                    state={{
                      id: e.id,
                      name: e.name,
                      descr: e.description,
                      parentId: e.parentId,
                    }}
                  >
                    <BtnAdmin title={"Edit"} bgcolor="green" />
                  </Link>

                  <Link
                    to={"/Admin/Addproduct"}
                    state={{ id: e.id, name: e.name }}
                  >
                    <BtnAdmin title={"Add Product"} bgcolor="#2196f3" />
                  </Link>
                  <BtnAdmin
                    title={loading ? "loading...." : "Delete"}
                    onclick={() => handleDeleteCat(e.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
