import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import TableInfo from "./TableInfo";
import CartProduct from "../page_offer/Cartproduct/CartProduct";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoSwpier from "./Info_Swpier";
import InfoProduct from "./InfoProduct";
import TabsRelation from "./TabsRelation";
import { ContextPro } from "../../App";
import BtnAdmin from "../Admin/BtnAdmin";

import { Admin } from "../../utils/AdminUser";
import { adminPanelAPIs } from "../../utils/config";
import { urlApiGet } from "../../utils/utilsFuc";

import "../page_offer/Cartproduct/cart.css";
import "../page_offer/Cartproduct/filter.css";
import "./info.css";

export default function page_Product() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { idUser } = useContext(ContextPro);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const loc = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [datainfo, setDataInfo] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const path = useParams();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    urlApiGet(adminPanelAPIs.Product.getProductURL, {
      id: path.name,
    }).then((e) => {
      setDataInfo(e.list);
     

      if (!e.list.length || e.list.length > 1) {
       // navigate("/");
      }
    });
  }, [path.name]);
 
  return (
    <div className="all-body mt-4 container">
      {/*  <ul className="px-3 my-3 breadcrumb">
        <li>
          <FontAwesomeIcon icon={faHome} color="#8b8b8b" />
        </li>
        <li>Special Offers</li>
      </ul> */}

      <div className="content-body-product px-3">
        <div className="wrap-swiper">
          <InfoSwpier data={datainfo[0]} />
        </div>
        <InfoProduct
          data={datainfo[0]}
          photo={datainfo[0]?.productAttchments}
        />
      </div>
      <TableInfo data={datainfo[0]} />

      {/*  <TabsRelation /> */}
    </div>
  );
}
