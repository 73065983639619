import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { urlApiEd } from "../../../utils/utilsFuc";
import { adminPanelAPIs } from "../../../utils/config";
import ClientOrder from "./ClientOrder";

function PageOrder() {
  
  const [data, setData] = useState([]);
  useEffect(() => {
    urlApiEd(
      adminPanelAPIs.Order.getOrder,
      {
        pageSize:100
      },
      {
        "Content-Type": "application/json",
        " Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    ).then((e) => {
      setData(e.list);
    });
  }, []);

  return data?.map((e) =>
    e?.orderItems?.length ? (
      <ClientOrder e={e}/>
    ) : (
      ""
    )
  );
}

export default PageOrder;
