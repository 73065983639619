import { useContext } from "react";
import { Link } from "react-router-dom";

import {
  faCartShopping,
  faHouse,
  faPercent,
  faPerson,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextPro } from "../../App";

import { User } from "../../utils/AdminUser";

export default function BarLink() {
  const { idUser } = useContext(ContextPro);
  return (
    <div className="bar-link">
      <Link to={"/"}>
        <FontAwesomeIcon icon={faHouse} />
        <div>home</div>
      </Link>
      <Link  className=" offer">
        <FontAwesomeIcon icon={faPercent} />
        <div>offers</div>
      </Link>
      <Link to={"/cart"}>
        <FontAwesomeIcon icon={faCartShopping} />
        <div>cart shopping</div>
      </Link>
      {User() ? (
        <Link>
          <FontAwesomeIcon icon={faUser} />
          <div>{idUser?.name}</div>
        </Link>
      ) : (
        <Link to={"/login"}>
          <FontAwesomeIcon icon={faUser} />
          <div>Account</div>
        </Link>
      )}
    </div>
  );
}
